import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/partnerships.scss'

const Partnerships = () => {
  let images = useStaticQuery(graphql`{
  google: file(relativePath: {eq: "logos/google-partner-logo.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 180, layout: CONSTRAINED)
    }
  }
  shopify: file(relativePath: {eq: "logos/shopify-experts-logo.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 180, layout: CONSTRAINED)
    }
  }
  mailchimp: file(relativePath: {eq: "logos/mailchimp-experts-logo.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 180, layout: CONSTRAINED)
    }
  }
  wordpress: file(relativePath: {eq: "logos/wordpress-logo-colored.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 180, layout: CONSTRAINED)
    }
  }
  hootsuite: file(relativePath: {eq: "logos/hootsuite-partner-logo.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 180, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <article className="section-partnerships">
      <div className="container text-align-center">
        <header>
          <h2 className="dec-header--underline">ΣΥΝΕΡΓΑΤΕΣ</h2>
          <p>Στην προσπάθειά μας να εξασφαλίσουμε στους πελάτες ένα υψηλό επίπεδο στις υπηρεσίες που τους προσφέρουμε, έχουμε εξασφαλίσει συνεργασίες με σημαντικές εταιρείες οι οποιες συμβάλλουν στη διασφάλιση της υψηλής ποιότητας.</p>
        </header>
        <section className="partnerships__logos">
          <GatsbyImage
            image={images.google.childImageSharp.gatsbyImageData}
            alt="Google partner" />
          <GatsbyImage
            image={images.shopify.childImageSharp.gatsbyImageData}
            alt="Shopify Experts" />
          <GatsbyImage
            image={images.mailchimp.childImageSharp.gatsbyImageData}
            alt="MailChimp Certified Experts" />
          <GatsbyImage image={images.wordpress.childImageSharp.gatsbyImageData} alt="Wordpress" />
          <GatsbyImage
            image={images.hootsuite.childImageSharp.gatsbyImageData}
            alt="Hootsuite Solution Partner" />
        </section>
      </div>
    </article>
  );
}

export default Partnerships